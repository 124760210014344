var OSymmetricEncryption;
var OAsymmetricEncryption;

define([/* leave empty - may break OSCAR integration */], function() {
	"use strict";
OSymmetricEncryption = (function() {
	var secretKeyTable = null; //table of siteNum->secretKeys arrays
	var secretKeys = null;	//contains array of secret keys, with the first being the most recent added e.g. key3|key2|key1 - up to a max of MAX_NUM_SECRET_KEYS 
	var SECRET_KEYS = "secretKeys";
	var ONE_TIME_KEY_CACHE = "oneTimeKey";
	var MAX_NUM_SECRET_KEYS = 4;	//keep no more than this number of previous secret keys in memory; in the event of a decryption failure, the most recently entered will be tried first

	function getOneTimeEncryptionKeyFromAnchor() {
		var encryptionKey = window.location.hash;	//eg "#Password1!000000"
		if (encryptionKey.length > 0) {
			encryptionKey = encryptionKey.substring(1);
		}
		encryptionKey = encryptionKey.substring(0, encryptionKey.indexOf("-")) || encryptionKey;
		if (encryptionKey.length === 24) {
			return encryptionKey;
		}
		return null;
	}
	function saveSecretKeys(cb) {
		OApp.getSiteNum(function(siteNum) {
			secretKeyTable[siteNum] = secretKeys;
			OUtils.setLocalStorage(SECRET_KEYS, JSON.stringify(secretKeyTable));
			if (cb) { cb(); }
		});
	}
	function loadSecretKeys(callback) {
		if (secretKeys) {
			callback(secretKeys);
		}
		else {
			OApp.getSiteNum(function(siteNum) {
				function importOld() {
					if (secretKeyTable["old"]) {
						secretKeys = secretKeyTable["old"];
					}
					else {
						var oldVal = OUtils.getLocalStorage("secretKey");
						if (oldVal) {
							secretKeyTable = {};
							secretKeys = oldVal.split("|");
							secretKeyTable[siteNum] = secretKeys;
							secretKeyTable["old"] = secretKeys;
							OUtils.setLocalStorage("secretKey", null);
							saveSecretKeys();
						}
					}
				}
				var secretKeysJSON = OUtils.getLocalStorage(SECRET_KEYS);
				secretKeyTable = secretKeysJSON ? JSON.parse(secretKeysJSON) : {};
				secretKeys = secretKeyTable[siteNum] || null;
				if (!secretKeys) {
					importOld();
				}

				callback(secretKeys);
			});
		}
	}
	function isSecretKeyEntered(callback) {
		loadSecretKeys(function(keys) {
			callback(keys !== null);
		});
	}
	function isPrimarySecretKeyEntered(callback) {
		getPrimarySecretKey(
			function() { callback(true); },
			function() { callback(false); }
		);
	}
	function getSecretKeys(callback) {
		loadSecretKeys(function() {
			if (secretKeys === null) {
				getNewSecretKey(function() {
					callback(secretKeys);
				});
			} else {
				callback(secretKeys);
			}
		});
	}
	// this is a back door for the OSCAR integration to set the secret key for note
	// decryption without the localStorage and related getSiteNum code kicking in.
	function setSecretKey(secretKey) {
		secretKeys = [secretKey];
	}
	var PK_CACHE = "usePKeyCache", usePKCache;
	function getPrimarySecretKey(cb, noPKCallback) {
		isSecretKeyEntered(function(keyEntered) {
			if (!keyEntered && noPKCallback) {
				noPKCallback();
				return;
			}
			getSecretKeys(function(keys) {
				OApp.getUser(function(user) {
					usePKCache = usePKCache || !user || sessionStorage.getItem(PK_CACHE);
					if (usePKCache) {
						if (secretKeys.length > 0) {
							cb(secretKeys[0]);
						}
						else {
							getNewSecretKey(cb);
						}
						return;
					}
					var hashes = [];
					for (var i = 0; i < secretKeys.length; i++) {
						hashes.push(OSymmetricEncryption.hashIt(convertBase64ToPassword(secretKeys[i])));
					}
					requirejs(["dwr/interface/SiteSettingsRpc"], function(SiteSettingsRpc) {
						SiteSettingsRpc.findPrimaryKey(hashes, function(index) {
							if (index >= 0) {
								var primaryKey = secretKeys[index];
								if (index > 0) {
									secretKeys[index] = secretKeys[0];							
									secretKeys[0] = primaryKey;
									saveSecretKeys();
								}
								usePKCache = true;
								try { sessionStorage.setItem(PK_CACHE, true); } catch(e) {}
								cb(primaryKey);
							}
							else {
								if (noPKCallback) {
									noPKCallback();
								}
								else {
									getNewSecretKey(cb);												
								}
							}
						});
					});
				});
			});
		});
	}
	function getNewSecretKey(callback) {
		requirejs(["site/encryption/SiteEncryption"], function(SiteEncryption) {
			SiteEncryption.showAsPopup({
				keyChosenCallback: callback
			});
		});
	}
	function convertSecretKeyPasswordToBytes(keyStr) {
		if (keyStr.length === 16) {
			// Add sentinel to allow us to distinguish between legacy < 16 char keys
			// and new 16 char keys.  This will automatically be stripped during decrypt
			// and will only be used when deciding whether to strip trailing zeroes. 
			keyStr += "\x01";
		} else {
			while (keyStr.length < 16) {
				//pad to 16
				keyStr += "0";
			}
		}
		return CryptoJS.enc.Utf8.parse(keyStr);
	}
	function convertKeyToBase64(secretKey) {
		var keyBytes = convertSecretKeyPasswordToBytes(secretKey);
		return bytesToBase64(keyBytes);
	}
	function convertBase64ToPassword(key) {
		var keyBytes = base64ToBytes(key);
		var pw = CryptoJS.enc.Utf8.stringify(keyBytes);
		if (pw.length === 17 && pw.charAt(16) === "\x01") {
			pw = pw.substr(0, 16);
		} else if (pw.length <= 16) {
			while (true) {//strip trailing 0's
				var lastIndex = pw.lastIndexOf("0");
				if (lastIndex === -1 || lastIndex + 1 != pw.length) {
					break;
				}
				pw = pw.substr(0,pw.length-1);
			}
		}
		return pw;
	}
	function addNewSecretKey(newSecretKeyPassword, addAsSecondaryKey) {
		addNewSecretKeyBase64(convertKeyToBase64(newSecretKeyPassword), addAsSecondaryKey);
	}
	function clearSecretKeysFromBrowser() {
		OUtils.setLocalStorage(SECRET_KEYS, null);
	}
	function addNewSecretKeyBase64(newSecretKey, addAsSecondaryKey, cb) {
		loadSecretKeys(function(keys) {
			keys = keys || [];
			if (newSecretKey && newSecretKey.length > 0) {
				for (var i = 0; i < keys.length; i++) {
					if (keys[i] === newSecretKey) {
						keys.splice(i, 1);//remove it
					}
				}
				if (addAsSecondaryKey && keys.length > 0) {
					keys.splice(1, 0, newSecretKey);//new secondary key goes to index 1
				}
				else {
					keys.splice(0, 0, newSecretKey);//primary key goes to index 0
				}
				while (keys.length > MAX_NUM_SECRET_KEYS) {
					keys.pop();
				}
			}
			secretKeys = keys;
			saveSecretKeys(cb);
		});
	}
	
	function bytesToBase64(bytes) {
		return CryptoJS.enc.Base64.stringify(bytes);
	}
	function base64ToBytes(str) {
		return CryptoJS.enc.Base64.parse(str);
	}
	function generateOneTimeKey() {
		return bytesToBase64(CryptoJS.lib.WordArray.random(16));
	}

	function decryptBlock(block, keyBytes) {
		var ivBytes = base64ToBytes(block.iv);
		var decryptedBytes = CryptoJS.AES.decrypt(block.data, truncateToUsableAESKey(keyBytes), {iv: ivBytes});
		return decryptedBytes;
	}
	function decryptBlockToBase64(block, key) {
		var keyBytes = base64ToBytes(key);
		return bytesToBase64(decryptBlock(block, keyBytes));
	}
	function truncateToUsableAESKey(keyBytes) {
		if (keyBytes.sigBytes > 32) {
			keyBytes.sigBytes = 32;
		} else if (keyBytes.sigBytes > 24 && keyBytes.sigBytes < 32) {
			keyBytes.sigBytes = 24;
		} else if (keyBytes.sigBytes > 16 && keyBytes.sigBytes < 24) {
			keyBytes.sigBytes = 16;
		}
		
		return keyBytes;
	}
	function decryptBlockToUtf8String(block, key) {
		var keyBytes = base64ToBytes(key);
		return CryptoJS.enc.Utf8.stringify(decryptBlock(block, keyBytes));
	}
	function encryptUtf8String(str, args) { //optional args: {key, secretKey, keyBytes, iv, ivBytes}
		args.encoding = "utf8";
		return encryptString(str, args);
	}
	function encryptBase64String(str, args) { //optional args: {key, secretKey, keyBytes, iv, ivBytes}
		args.encoding = "base64";
		return encryptString(str, args);
	}
	function encryptString(str, args) { //optional args: {key, secretKey, keyBytes, iv, ivBytes, encoding}
		var keyBytes = args.keyBytes;
		if (args.key) {
			keyBytes = base64ToBytes(args.key);
		}
		var ivBytes = args.ivBytes;
		if (args.iv) {
			ivBytes = base64ToBytes(args.iv);
		}
		if (!ivBytes) {
			ivBytes = CryptoJS.lib.WordArray.random(16);
		}
		var strBytes = args.encoding == "utf8" ? CryptoJS.enc.Utf8.parse(str) : base64ToBytes(str);
		
		var encryptedCryptoJS = CryptoJS.AES.encrypt(strBytes, truncateToUsableAESKey(keyBytes), {iv: ivBytes});
		return {
			data: bytesToBase64(encryptedCryptoJS.ciphertext),
			iv: bytesToBase64(ivBytes)
		};
	}

	function getCachedOneTimeKey() {
		var cookie = OUtils.getLocalStorage(ONE_TIME_KEY_CACHE);
		if (cookie) {
			var split = cookie.split("|");
			return {key: split[0], ref: split[1]};
		}
	}
	function setCachedOneTimeKey(oneTimeKey, ptRef) {
		return OUtils.setLocalStorage(ONE_TIME_KEY_CACHE, oneTimeKey+"|"+ptRef);
	}
	function decryptPtDto(encryptedPtDto, callback, failureCallback) {
		var cachedOneTimeKey = getCachedOneTimeKey();
		try {
			if (cachedOneTimeKey && cachedOneTimeKey.ref == encryptedPtDto.ref) {
				var pt = decryptPtDtoWithKey(encryptedPtDto, cachedOneTimeKey.key);
				callback(pt);
				return;
			}
		}
		catch (e) {
			console.error("Unable to decrypt patient "+cachedOneTimeKey.ref+" with oneTimeKey " + cachedOneTimeKey.key);
		}
		decryptPtDtoWithSecretKeys(encryptedPtDto, callback, failureCallback);
	}

    /**
	 * Extracts fields from the envelope, then sets those fields on the decrypted patient.
	 *
	 * Update: any new fields should be added as sub-fields on the 'env' field of the decrypted patient.
	 *
     * @param pt
     * @param encryptedPtDto
     */
	function setPtFieldsFromDto(pt, encryptedPtDto) {
		pt.ref = encryptedPtDto.ref;
		pt.siteNum = encryptedPtDto.siteNum;
		pt.externalPatientRef = encryptedPtDto.externalPatientRef;
        pt.env = {
        	appts: encryptedPtDto.appts,
            apptDateTime: encryptedPtDto.apptDateTime,
			apptDateTimeStr: encryptedPtDto.apptDateTimeStr,
			providerName: encryptedPtDto.providerName
        };
	}
	function decryptPtDtoWithSecretKeys(encryptedPtDto, callback, failureCallback) {
		decryptPtWithSecretKeys(encryptedPtDto.oneTimeKeyEncryptedUsingSecretEmrKey,
			encryptedPtDto.ptData, function(pt, oneTimeKey) {
				setPtFieldsFromDto(pt, encryptedPtDto);
				encryptedPtDto.oneTimeKey = oneTimeKey;
				callback(pt);
		}, failureCallback);
	}
	function decryptPtWithSecretKeys(encryptedOneTimeKey, block, callback, failureCallback) {
		if (!failureCallback) {
			failureCallback = function(e) {
				OUtils.showError(OUtils.t("The decryption failed. Details: {{e}}", "OEncryption.js.decryption_failed_details", {e: e}));
				if (e) {
					console.log("decryptPtDtoWithSecretKeys.failureCallback");
					console.log(e);
				}
			};
		}
		decryptWithSecretKeys(
				{	encryptedOneTimeKey: encryptedOneTimeKey,
					block: block,
					failureCallback: failureCallback
				}, function(decryptedText, oneTimeKey) {
			var pt = null;
			try {
				pt = parsePatient(decryptedText);//raw JSON patient
			} catch (e) { failureCallback(e, decryptedText); }
			if (pt) {
				callback(pt, oneTimeKey);
			}
		}, failureCallback);
	}
	function decryptWithSecretKeys(args, callback) {
		var encryptedOneTimeKey = args.encryptedOneTimeKey;
		var block = args.block || (args.encryptedPtDto ? args.encryptedPtDto.ptData : null);
		//consider adding arg.decodeToBase64 if end format is base64 instead of utf8
		
		getSecretKeys(function(keys) {
			var exception = null;
			var decryptedText = null;
			var oneTimeKey = null;
			var secretKey = null;
			for (var i = 0; i < keys.length; i++) {
				try {
					secretKey = keys[i];
					var key = secretKey;
					if (encryptedOneTimeKey) {
						oneTimeKey = decryptBlockToBase64(encryptedOneTimeKey, key);
						key = oneTimeKey;
					}
					if (block) {
						decryptedText = decryptBlockToUtf8String(block, key);
					}
					exception = null; // clear exception if a previous key failed
					break; // decrypt succeeded, so break out
				}
				catch (e) {
					exception = e;
				}
			}
			if (exception !== null) {
				if (args.failureCallback) {
					args.failureCallback(exception);
				} else {
					OUtils.showError(exception, function() {
						//couldn't decrypt; let's ask for the key again in case it was typed incorrectly
						getNewSecretKey(function(secretKeys) { decryptWithSecretKeys(args, callback); });
					});
				}
			} else {
				callback(decryptedText, oneTimeKey, secretKey);
			}
		});
	}
	function decryptPtDtoWithKey(encryptedPtDto, oneTimeKey) {
		encryptedPtDto.oneTimeKey = oneTimeKey;//cache for later
		var decryptedText = decryptBlockToUtf8String(encryptedPtDto.ptData, oneTimeKey);
		var pt = parsePatient(decryptedText);
		setPtFieldsFromDto(pt, encryptedPtDto);
		return pt;
	}
	function decryptPtDtoWithSecretKey(encryptedPtDto, secretKey) {
		var encryptedOneTimeKey = encryptedPtDto.oneTimeKeyEncryptedUsingSecretEmrKey;
		var oneTimeKey = decryptBlockToBase64(encryptedOneTimeKey, secretKey);
		return decryptPtDtoWithKey(encryptedPtDto, oneTimeKey);
	}
	function decryptPtUpdate(encryptedPtUpdate, key) {
		return new Patient.PtUpdate(decrypt(encryptedPtUpdate, key));
	}
	function decrypt(encryptedBlock, key) {
		var decryptedText = decryptBlockToUtf8String(encryptedBlock, key);
		return parseJsonObj(decryptedText);
	}
	function decryptPtData(encryptedPtData, oneTimeKey) {
		var decryptedText = decryptBlockToUtf8String(encryptedPtData, oneTimeKey);
		var pt = parsePatient(decryptedText);
		return pt;
	}
	function encryptNewPtDtoWithSecretKey(encryptedPtDto, pt, primarySecretKey, iv) {
		if (!encryptedPtDto.oneTimeKey) {
			encryptedPtDto.oneTimeKey = generateOneTimeKey();	//generate a new one-time key since this is presumably the first encryption for the patient
		}
		var oneTimeKey = encryptedPtDto.oneTimeKey;
		encryptedPtDto.ptData = encryptPt(pt, oneTimeKey, iv);
		encryptedPtDto.oneTimeKeyEncryptedUsingSecretEmrKey = encryptBase64String(oneTimeKey, {key: primarySecretKey, iv: encryptedPtDto.ptData.iv});
	}
	function encryptPt(pt, oneTimeKey, iv) {
		return encrypt(pt, oneTimeKey, iv);
	}
	function encrypt(obj, oneTimeKey, iv) {
		var json = JSON.stringify(obj);
		//encrypt the patient data using the patient's one-time key:
		var block = encryptUtf8String(json, {key: oneTimeKey, iv: iv});
		return block;
	}

	function parseJsonObj(decryptedText) {
		if(typeof decryptedText !== "string") {
			throw OUtils.t("The JSON in this object is invalid: not a string", "OEncryption.js.json_invalid_not_a_string");
		}
		if (!decryptedText || decryptedText.trim().charAt(0) !== "{") {
			console.log(decryptedText);
			throw OUtils.t("The decryption failed. Please confirm that you are using the correct decryption key, then try again.", "OEncryption.js.decryption_failed_confirm_key");
		}
		/*jshint evil: true */
		decryptedText = decryptedText.replace(/\n/g, "\\n");
		try {
			var jsonObj = null;
			jsonObj = JSON.parse(decryptedText);
			cleanObject(jsonObj);
			return jsonObj;
		}
		catch(e) {
			console.log(decryptedText);
			console.log(e);
			throw OUtils.t("The JSON in this object is invalid: {{decryptedText}}", "OEncryption.js.json_invalid", {decryptedText: decryptedText});
		}
	}
	// Parsed JSON can contain __proto__ and constructor fields which can pollute prototypes.  Strip them out in-place
	function cleanObject(obj) {
		var visitQueue = [obj];
		
		while(visitQueue.length) {
			var next = visitQueue;
			visitQueue = [];
			for(var i = 0; i < next.length; i++) {
				var node = next[i];
				// Remove __proto__ fields
				if(Object.prototype.hasOwnProperty.call(node, '__proto__')) {	// Use the true prototype call to avoid prototype poisoning
					delete node.__proto__;
				}
				// Remove constructor fields
				if(Object.prototype.hasOwnProperty.call(node, 'constructor') && Object.prototype.hasOwnProperty.call(node.constructor, 'prototype')) { // Use the true prototype call to avoid prototype poisoning
					delete node.constructor;
				}
				
				for(var key in node) {
					var nextObj = node[key];
					if (nextObj && typeof nextObj === 'object') {
						visitQueue.push(nextObj);
					}
				}
			}
			
		}
	}
	
	function parsePatient(decryptedText) {
		var ptJsonObj = parseJsonObj(decryptedText);
		return new Patient(ptJsonObj);
	}
	/**
	 * This computes an approximate birth date useful for the generateFormMemoryHash function below.
	 */
	function computeApproxBirthDate(fromDate, approxAgeInDays) {
		if(fromDate === null || approxAgeInDays === null) {
			return null;
		}
		var dt = new Date(fromDate - (approxAgeInDays * 24 * 60 * 60 * 1000));
		// use Jan 1 of the calculated year
		return new Date(dt.getFullYear(), 0, 1);
	}
	/**
	 * This must be sync'ed with the same method in WaveEncryptionUtil.java in CognisantKiosk project!!!
	 */
	function generateFormMemoryHash(pt, encryptedPt, formRef, saltBase64) {
		if (!pt) {
			console.log("Cannot generate hash because patient data was not provided.");
			return null;
		}
		var siteNum = pt.siteNum;
		var surname = pt.demographics.surname;
		var sex = pt.demographics.sex;
		var hn = pt.demographics.hn;
		var birthDate = pt.getBirthDate();
		if (!birthDate && encryptedPt) {
			birthDate = computeApproxBirthDate(encryptedPt.creationDate, pt.demographics.approxAgeInDays);
		}
		
		if (!saltBase64) {
			console.log("Cannot generate hash because salt is not set!");
			return null;
		}
		if (!siteNum || !surname || !sex || !birthDate || !formRef) { 
			console.log("Cannot generate hash because a field is empty!");
			return null; // need all to hash securely
		}
		var hnSnippet;
		if (!hn || hn.length < 10) {
			hnSnippet = "";
		} else {
			hnSnippet = hn.substring(2, 6);	// avoid version code at end
		}
		var uniqueStr = saltBase64 + siteNum + "|" + surname + "|" + sex + "|" + $.datepicker.formatDate("yymmdd", birthDate) + "|" + hnSnippet + "|" + formRef;
		return hashIt(uniqueStr);
	}
	function hashIt(str) {
		for (var i = 0; i < 1000; ++i) {
			str = CryptoJS.SHA256(str);
		}
		return bytesToBase64(str);
	}
	function hashPtFields(pt) {
		var demog = pt.demographics;
		return {
			surname3: hashIt(demog.surname.toLowerCase().substr(0,3)),
			hn: demog.hn ? hashIt(demog.hn) : null,
			alternateId: hashIt(demog.alternateId),
			birthDate: hashIt(demog.birthDate ? demog.birthDate : null)
		};
	}
	function generateRandomKey() {
		function randomChar(chars) {
            var rnum = Math.floor(Math.random() * chars.length);
            return chars.substring(rnum,rnum+1);
		}
		function stringSplice(str, index, count, add) {
			return str.slice(0, index) + (add || "") + str.slice(index + count);
		}
		var lCase = "abcdefghijkmnopqrstuvwxyz";//some chars excluded due to visual ambiguity
		var uCase = "ABCDEFGHJKLMNPQRSTUVWXYZ";
		var digits = "123456789";
		var puncs = "!@#$%^&*()<>=_.";
		var generalChars = lCase + uCase + digits;
		var pwLength = 16;
        var key = "";
        for (var i = 0; i < pwLength-4; i++) {
        	key += randomChar(generalChars);
        }
        key = stringSplice(key, Math.floor(Math.random() * key.length), 0, randomChar(lCase));
        key = stringSplice(key, Math.floor(Math.random() * key.length), 0, randomChar(uCase));
        key = stringSplice(key, Math.floor(Math.random() * key.length), 0, randomChar(digits));
        key = stringSplice(key, Math.floor(Math.random() * key.length), 0, randomChar(puncs));
        return key;
	}
	
	return {	//OSymmetricEncryption
		getSecretKeys: getSecretKeys,
		setSecretKey: setSecretKey,	// allow OSCAR integration to bypass localStorage secret key lookup
		getPrimarySecretKey: getPrimarySecretKey,
		getNewSecretKey: getNewSecretKey,
		clearSecretKeysFromBrowser: clearSecretKeysFromBrowser,
		generateRandomKey: generateRandomKey,
		addNewSecretKey: addNewSecretKey,
		addNewSecretKeyBase64: addNewSecretKeyBase64,
		isPrimarySecretKeyEntered: isPrimarySecretKeyEntered,
		isSecretKeyEntered: isSecretKeyEntered,
		generateOneTimeKey: generateOneTimeKey,
		convertKeyToBase64: convertKeyToBase64,
		convertBase64ToPassword: convertBase64ToPassword,

		encryptUtf8String: encryptUtf8String,
		encryptBase64String: encryptBase64String,

		encrypt: encrypt,
		encryptPt: encryptPt,
		encryptNewPtDtoWithSecretKey: encryptNewPtDtoWithSecretKey,

		decryptBlockToBase64: decryptBlockToBase64,
		decryptBlockToUtf8String: decryptBlockToUtf8String,
		decryptPtData: decryptPtData,
		decryptPtUpdate: decryptPtUpdate,
		decryptPtWithSecretKeys: decryptPtWithSecretKeys,
		decryptWithSecretKeys: decryptWithSecretKeys,
		decryptPtDtoWithSecretKeys: decryptPtDtoWithSecretKeys,
		decryptPtDtoWithSecretKey: decryptPtDtoWithSecretKey,
		decryptPtDtoWithKey: decryptPtDtoWithKey,
		decryptPtDto: decryptPtDto,
		decrypt: decrypt,
		
		generateFormMemoryHash : generateFormMemoryHash,
		setCachedOneTimeKey: setCachedOneTimeKey,
		getOneTimeEncryptionKeyFromAnchor: getOneTimeEncryptionKeyFromAnchor,
		hashIt: hashIt,
		hashPtFields: hashPtFields
	};
})();

OAsymmetricEncryption = (function() {
	/**
	 * encrypts plainText using the publicKey, which is a JSON-encoded string containing the RSA key vars
	 * @return the encrypted text as a hex string
	 */
	function encryptWithPublicKey(publicKey, plainText) {
		if (typeof publicKey == "string")
			publicKey = JSON.parse(publicKey);
		var rsa = new RSAKey();
		rsa.setPublic(publicKey.n, publicKey.e);
		var cipherTextHex = rsa.encrypt(plainText);
		return cipherTextHex;
	}
	/**
	 * encrypts plainText using the publicKey, which is a JSON-encoded string containing the RSA key vars
	 * @return the decrypted plaintext as a string
	 */
	function decryptWithPrivateKeys(privateKeys, cipherTextHex) {
		var e = null;
		if (!privateKeys || privateKeys.length === 0) {
			throw OUtils.t("no private keys specified", "OEncryption.js.no_private_keys_specified");
		}
		for (var i = 0; i < privateKeys.length; i++) {
			//try all the private keys until one works
			try {
				var plainText = decryptWithPrivateKey(privateKeys[i], cipherTextHex);
				if (plainText) {
					return plainText;
				}
				throw OUtils.t("asymmetric decryption failed", "OEncryption.js.asymmetric_decryption_failed");
			}
			catch (exc) {
				e = exc;
			}
		}
		throw e;
	}
	function decryptWithPrivateKey(privateKey, cipherTextHex) {
		if (typeof privateKey == "string")
			privateKey = JSON.parse(privateKey);
		var rsa = new RSAKey();
		rsa.setPrivateEx(privateKey.n, privateKey.e, privateKey.d, privateKey.p,
				privateKey.q, privateKey.dmp1, privateKey.dmq1, privateKey.coeff);
		var decryptedText = rsa.decrypt(cipherTextHex);
		return decryptedText;
	}

	function encryptWithPublicKeyAsBase64(publicKey, plainText) {
		const ciphertextAsHex = encryptWithPublicKey(publicKey, plainText);
		const ciphertextAsWordArray = CryptoJS.enc.Hex.parse(ciphertextAsHex);
		const ciphertextAsBase64 = CryptoJS.enc.Base64.stringify(ciphertextAsWordArray);
		return ciphertextAsBase64;
	}

	function createFingerprint(publicKey, patientId, idJurisdiction) {
		if (publicKey == null || patientId == null) {
			return null;
		}
		const fingerprintJson = {
			indentifier: patientId,
			idJurisdiction: idJurisdiction || '',
			salt: CryptoJS.lib.WordArray.random(16).toString(),
		};
		return encryptWithPublicKeyAsBase64(publicKey, JSON.stringify(fingerprintJson));
	}


	function generateAsn1FromPrivateKey(privateKey) {
		if (typeof privateKey == 'string') {
			privateKey = JSON.parse(privateKey);
		}
		const rsa = new RSAKey();
		rsa.setPrivateEx(privateKey.n, privateKey.e, privateKey.d, privateKey.p,
			privateKey.q, privateKey.dmp1, privateKey.dmq1, privateKey.coeff);

		const keyAsAns1String = [
			'asn1=SEQUENCE:rsa_key',
			'',
			'[rsa_key]',
			'version=INTEGER:0',
			`modulus=INTEGER:${rsa.n}`,
			`publicExponent=INTEGER:${rsa.e}`,
			`privateExponent=INTEGER:${rsa.d}`,
			`prime1=INTEGER:${rsa.p}`,
			`prime2=INTEGER:${rsa.q}`,
			`exponent1=INTEGER:${rsa.dmp1}`,
			`exponent2=INTEGER:${rsa.dmq1}`,
			`coefficient=INTEGER:${rsa.coeff}`,
		].join('\n');

		return keyAsAns1String;
	}

	/**
	 * generates a new random public/private key pair
	 * privateKey: JSON-encoded string of the private key RSA vars (n,e,d,p,q,dmp1,dmq1,coeff)
	 * @return {publicKey: JSON-encoded string of the public key RSA vars (n, e)
	 * }
	 */
	function generateRsaKeyPair() {
		var rsa = new RSAKey();
		rsa.generate(1024, "10001");//TODO use different public exp hexes
		return {
			publicKey: JSON.stringify ({
				n: rsa.n.toString(16),
				e: rsa.e.toString(16)
			}),
			privateKey: JSON.stringify({
				n: rsa.n.toString(16),
				e: rsa.e.toString(16),
				d: rsa.d.toString(16),
				p: rsa.p.toString(16),
				q: rsa.q.toString(16),
				dmp1: rsa.dmp1.toString(16),
				dmq1: rsa.dmq1.toString(16),
				coeff: rsa.coeff.toString(16)
			})
		};
	}
	
	return {	//OAsymmetricEncryption
		generateAsn1FromPrivateKey: generateAsn1FromPrivateKey,
		generateRsaKeyPair: generateRsaKeyPair,
		encryptWithPublicKey: encryptWithPublicKey,
		decryptWithPrivateKeys: decryptWithPrivateKeys,
		decryptWithPrivateKey: decryptWithPrivateKey,
		createFingerprint: createFingerprint,
	};
})();
return {
	OSymmetricEncryption: OSymmetricEncryption,
	OAsymmetricEncryption: OAsymmetricEncryption
};
});
